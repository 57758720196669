import { defineStore } from 'pinia';
import { useColors } from '@/composables/useColors';
const { getRandomDarkColor } = useColors()

export const useNavStore = defineStore('nav', {
  state: () => ({
    currentPage: 'index',
    bg: '#f7e305'
  }),
  actions: {
    setPage(page) {
        this.currentPage = page;
    },
    changeBg() {
      this.bg = getRandomDarkColor();
  },
  },
});