import { defineStore } from 'pinia';

export const useGameStore = defineStore('game', {
  state: () => ({
    player: null,
    gameMatch: null,
    questionsNum: 5,
    questions: [],
    currentQuestionIndex: 0,
    answers: [],
    opponentAnswers: [],
  }),
  getters: {
    gameId(state){
      return state.gameMatch.id
    },
    playerId(state){
      return state.player.id
    }
  },
  actions: {
    setPlayer(player) {
      this.player = player;
    },
    setGameMatch(gameMatch) {
      this.gameMatch = gameMatch;
      this.questions = gameMatch.questions || [];
      this.currentQuestionIndex = 0;
      this.answers = Array(this.questions.length).fill(null);
    },
    setCurrentQuestionIndex(index) {
      this.currentQuestionIndex = index;
    },
    setAnswer(index, answer) {
      this.answers[index] = answer;
    },
    clearGame() {
      this.player = null;
      this.gameMatch = null;
      this.questions = [];
      this.currentQuestionIndex = 0;
      this.answers = [];
    },
  },
  // persist: true
});