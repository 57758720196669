import { ref } from 'vue'

export function useColors() {
  const colors = ref([
    '#00876e',
    '#2b70fa',
    '#61d9f0',
    '#9ef040',
    '#b080ff',
    '#d40d47',
    '#de052e',
    '#f02e6e',
    // '#f7e305',
    '#ffad00'
  ]);

  function hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const fullHex = hex.length === 4
      ? '#' + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3]
      : hex;
  
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  function getLuminance({ r, g, b }: { r: number, g: number, b: number }) {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  function isDarkColor(hex: string) {
    const rgb = hexToRgb(hex);
    if (!rgb) return false;
    const luminance = getLuminance(rgb);
    return luminance < 0.5; // Adjust threshold as needed
  }

  function getRandomColors(count: number, excludeColor: string | null = null): string[] {
    const filteredColors = excludeColor ? colors.value.filter(color => color !== excludeColor) : colors.value;
    const shuffledColors = [...filteredColors].sort(() => 0.5 - Math.random());
    return shuffledColors.slice(0, count);
  }

  function getRandomDarkColor() {
    const darkColors = colors.value.filter(isDarkColor);
    const randomIndex = Math.floor(Math.random() * darkColors.length);
    return darkColors[randomIndex];
  }

  return {
    colors,
    getRandomColors,
    getRandomDarkColor
  }
}